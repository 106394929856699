html,
body {
  /* font-family: 'Fira Code', sans-serif; */
  font-family: 'Cascadia Code PL', sans-serif;
  /* font-family: 'Operator Mono Lig', sans-serif; */
  /* font-family: 'Dank Mono', sans-serif; */
  font-weight: 400;
}

.feed-post__controls {
  opacity: 0;
  transition: opacity 0.5s;
}

.feed-post__bottom-row:hover .feed-post__controls {
  opacity: 1;
}
