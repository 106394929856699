@import './scss/custom';

html,
body,
#root {
  height: 100%;
}

textarea.post-textarea {
  width: 100%;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  // overflow: auto;
  // resize: none;
  height: 95%;
  overflow: auto;
}